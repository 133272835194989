import Common from '@/assets/js/common'

export default {
	methods: {
		getAllColors() {
			return [
				"#FF7C24",
				"#D11543",
				"#FFC031",
				"#54AFE2",
				"#EB3C96",
				"#197D8D",
				"#532B87",
				"#C279D6",
				"#3A5827",
				"#158D4D",
				"#14529B",
				"#B86A55",
				"#90AEAE",
				"#CE923A",
			]
		},

		getTags(fk, type) {
			return this.$storage.db.t('tags')
			.then(table => {
				return table.where({
					tags_type: type,
					tags_fk: fk
				})
			})
			.then(col => {
				return col.toArray()
			})
		},

		async setTags(fk, type, color) {
			return this.$storage.db.t('tags')
            .then(table => {
                return table.add({
                	tags_id: Common.getNegativeId(),
                	tags_type: type,
                    tags_fk: fk,
                    tags_color: color
                })
            })
		},

		deleteTags(fk, type, color) {
			return this.$storage.db.t('tags')
			.then(table => {
				return table.where({
					tags_type: type,
					tags_fk: fk,
					tags_color: color
				}).invalid()
			})
		},
	}
}
